import React, { useEffect, useState } from "react";

import './css/navbar-phirst-serv.css'

import { NavLink } from "react-router-dom";

const NavbarPhirstServ = () => {
    const [menu, setMenu] = useState(false)

    useEffect(() => {
        let content = document.querySelector('.content')

        if (content) {
            content.addEventListener('click', () => {
                setMenu(false)
            })
            return () => {
                content.removeEventListener('click', () => {
                    setMenu(false)
                })
            }
        }

    })

    useEffect(() => {
        let navSmall = document.getElementById('navSmall')
        if (menu) {
            navSmall.classList.add('show')
        } else {
            navSmall.classList.remove('show')
        }
    }, [menu])

    const toggleMenu = () => {
        setMenu((prevState) => !prevState)
    }
    return (
        <>
            <nav className="sights-navbar" id="navBar">
                <NavLink to="/">
                    <img className="sights-logo" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights/images/others/sights-logo-v1.png" alt="" draggable="false" />
                </NavLink>
                <div className="nav-links desktop">
                    <NavLink to="" className={({ isActive }) => (isActive ? "active" : "")}>
                        Home
                    </NavLink>
                    {/* <NavLink to="amani-series">
                        Amani
                    </NavLink>
                    <NavLink to="alora-series">
                        Alora
                    </NavLink> */}
                    <NavLink to="about">
                        About Us
                    </NavLink>
                    <NavLink to="explore-sights">
                        Explore
                    </NavLink>
                    <NavLink to="contact-us">
                        Contact Us
                    </NavLink>
                    <NavLink to="phirst-serv">
                        PHirst Serv
                    </NavLink>
                    <NavLink to="privacy-policy">
                        Privacy Policy
                    </NavLink>
                </div>
                <div className="nav-links desktop-v2">
                    <NavLink to="" className={({ isActive }) => (isActive ? "active" : "")}>
                        Home
                    </NavLink>
                    <NavLink to="about">
                        About Us
                    </NavLink>
                    <NavLink to="explore-sights">
                        Explore
                    </NavLink>
                    <NavLink to="contact-us">
                        Contact Us
                    </NavLink>
                    <NavLink to="phirst-serv">
                        PHirst Serv
                    </NavLink>
                    <NavLink to="privacy-policy">
                        Privacy Policy
                    </NavLink>
                </div>
                <img className="menu-icon" src="https://phirstpark-editions.sgp1.cdn.digitaloceanspaces.com/phirst-sights%2Fimages%2Fothers%2Fsights-menu-icon.png" alt="" onClick={() => { toggleMenu() }} draggable="false" />
                <div className="nav-links small-screen" id="navSmall">
                    <div>
                        <NavLink to="" className={({ isActive }) => (isActive ? "active" : "")} onClick={() => { setMenu(false) }}>
                            Home
                        </NavLink>
                        {/* <NavLink to="amani-series" onClick={ () => { setMenu(false) }}>
                            Amani
                        </NavLink>
                        <NavLink to="alora-series" onClick={ () => { setMenu(false) }}>
                            Alora
                        </NavLink> */}
                        <NavLink to="about" onClick={() => { setMenu(false) }}>
                            About Us
                        </NavLink>
                        <NavLink to="explore-sights" onClick={() => { setMenu(false) }}>
                            Explore
                        </NavLink>
                        <NavLink to="contact-us" onClick={() => { setMenu(false) }}>
                            Contact Us
                        </NavLink>
                        <NavLink to="phirst-serv" onClick={() => { setMenu(false) }}>
                            PHirst Serv
                        </NavLink>
                        <NavLink to="privacy-policy" onClick={() => { setMenu(false) }}>
                            Privacy Policy
                        </NavLink>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default NavbarPhirstServ